import React from 'react';

import Main from './views/Main/Main';
import Dashboard from './views/Dashboard/Dashboard';
import CreateOrder from './views/Order/CreateOrder';
import OrderHistory from './views/Order/OrderHistory';
import Distributors from './views/Distributor/Distributors';
import Products from './views/Products/Products';
import Settings from './views/Settings/Settings';
import BrandAndModel from './views/BrandAndModel/BrandAndModel';
import GelirGider from './views/GelirGider/GelirGider';

const routes = [
   {path: '/main', exact: true, name: 'Anasayfa', component: Main},
   {path: '/dashboard', name: 'Anasayfa', component: Dashboard},
   {path: '/order/create', name: 'Satış Oluştur', component: CreateOrder},
   {path: '/order/history', name: 'Satış Geçmişi', component: OrderHistory},
   {path: '/distributor', name: 'Distribütörler', component: Distributors},
   {path: '/products', name: 'Stoklar', component: Products},
   {path: '/gelirgider', name: 'Gelir Gider', component: GelirGider},
   {path: '/settings', name: 'Ayarlar', component: Settings},
   {path: '/brandAndModel', name: 'Marka Ve Modeller', component: BrandAndModel},
];

export default routes;
