import React, {PureComponent} from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import * as BB from '../../components/customComponent'
import Button from "reactstrap/es/Button";
import {post} from 'axios';
import notify from 'devextreme/ui/notify';

class Distributors extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: [],
         selectedDisName: '',
         selectedDisPhone: '',
         selectedDisAdress: ''
      }

      this.getData();
   }

   getData()
   {
      BB.FetchJSON('api/Distributor/GetDis').then(data =>
      {
         this.setState({
            gridData: data,
            selectedDisName: '',
            selectedDisPhone: '',
            selectedDisAdress: ''
         });
      })
   }

   addData()
   {
      let {selectedDisName, selectedDisPhone, selectedDisAdress} = this.state;

      if (selectedDisName && selectedDisPhone && selectedDisAdress)
      {
         BB.FetchJSON('api/Distributor/AddOrUpdateDis?name=' + selectedDisName + '&phone=' + selectedDisPhone + '&adres=' + selectedDisAdress).then(res =>
         {
            if (res === 1)
            {
               this.getData();
               notify('Distribütör Başarıyla Kaydedildi!', "success", 2000);
            }
            else
            {
               notify('Distribütör Kaydederken Hata Alındı!', "error", 1000);
            }
         })
      }
      else
      {
         notify('Lütfen gerekli alanları doldurunuz!', "warning", 1000);
      }

   }

   deleteData(e)
   {
      let url = 'api/Distributor/DeleteDis?ID=' + e.data.id;
      let ths = this;

      BB.FetchJSON(url).then((res) =>
      {
         if (res)
         {
            notify('Başarılı!', 'success', 1000);
            ths.getData();
         }
         else
         {
            notify('Silme Başarısız, İlgili kayıt satışta kullanılmış!', 'error', 2000);
            ths.getData();
         }
      });
   }

   handleChange(key, value)
   {
      this.setState({[key]: value});
   }

   render()
   {
      return (
         <Row>
            <Row>
               <Col xl={2}>
                  <Card>
                     <CardHeader>
                        Distribütör Oluştur
                     </CardHeader>
                     <CardBody>
                        {BB.InputWithLabel('selectedDisName', 'Firma İsmi Giriniz', 'text', this, '')}
                        {BB.InputWithLabel('selectedDisPhone', 'Firma Telefon Giriniz', 'text', this, '')}
                        {BB.TextAreaWithLabel('selectedDisAdress', 'Firma Adresi Giriniz', '', '5', this)}
                        <Button
                           style={{float: 'right'}}
                           color={'primary'}
                           block
                           onClick={() => this.addData()}
                        >
                           Kaydet
                        </Button>
                     </CardBody>
                  </Card>
               </Col>
               <Col xl={10}>
                  <Card>
                     <CardHeader>
                        Distribütör Listesi
                     </CardHeader>
                     <CardBody>
                        {BB.DxDataGridDistributors(this.state.gridData, this)}
                     </CardBody>
                  </Card>
               </Col>

            </Row>
         </Row>
      );
   }
}

export default Distributors;
