import React, {PureComponent} from 'react';
import Row from "reactstrap/es/Row";

class Main extends PureComponent
{

   componentDidMount()
   {
      // console.log('Yusuf');
   }

   render()
   {
      return (
         <div>
         </div>
      );
   }
}

export default Main;
