import React, {Component} from 'react';
import {Bar, Doughnut, Line} from 'react-chartjs-2';
import {Card, CardBody, CardHeader, Col, Row,} from 'reactstrap';
import {CustomTooltips} from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import {getStyle} from '@coreui/coreui/dist/js/coreui-utilities'
import * as BB from '../../components/customComponent'
import {treeForEach} from "enzyme/src/RSTTraversal";

const brandPrimary = getStyle('--primary')
const brandInfo = getStyle('--info')

const cardChartData1 = {
   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
   datasets: [
      {
         label: 'My First dataset',
         backgroundColor: brandPrimary,
         borderColor: 'rgba(255,255,255,.55)',
         data: [65, 59, 84, 84, 51, 55, 40],
      },
   ],
};
const cardChartOpts1 = {
   tooltips: {
      enabled: false,
      custom: CustomTooltips
   },
   maintainAspectRatio: false,
   legend: {
      display: false,
   },
   scales: {
      xAxes: [
         {
            gridLines: {
               color: 'transparent',
               zeroLineColor: 'transparent',
            },
            ticks: {
               fontSize: 2,
               fontColor: 'transparent',
            },

         }],
      yAxes: [
         {
            display: false,
            ticks: {
               display: false,
               min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
               max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
            },
         }],
   },
   elements: {
      line: {
         borderWidth: 1,
      },
      point: {
         radius: 4,
         hitRadius: 10,
         hoverRadius: 4,
      },
   }
}
const cardChartData2 = {
   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
   datasets: [
      {
         label: 'My First dataset',
         backgroundColor: brandInfo,
         borderColor: 'rgba(255,255,255,.55)',
         data: [1, 18, 9, 17, 34, 22, 11],
      },
   ],
};
const cardChartOpts2 = {
   tooltips: {
      enabled: false,
      custom: CustomTooltips
   },
   maintainAspectRatio: false,
   legend: {
      display: false,
   },
   scales: {
      xAxes: [
         {
            gridLines: {
               color: 'transparent',
               zeroLineColor: 'transparent',
            },
            ticks: {
               fontSize: 2,
               fontColor: 'transparent',
            },

         }],
      yAxes: [
         {
            display: false,
            ticks: {
               display: false,
               min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
               max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5,
            },
         }],
   },
   elements: {
      line: {
         tension: 0.00001,
         borderWidth: 1,
      },
      point: {
         radius: 4,
         hitRadius: 10,
         hoverRadius: 4,
      },
   },
};
const cardChartData3 = {
   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
   datasets: [
      {
         label: 'My First dataset',
         backgroundColor: 'rgba(255,255,255,.2)',
         borderColor: 'rgba(255,255,255,.55)',
         data: [78, 81, 80, 45, 34, 12, 40],
      },
   ],
};
const cardChartOpts3 = {
   tooltips: {
      enabled: false,
      custom: CustomTooltips
   },
   maintainAspectRatio: false,
   legend: {
      display: false,
   },
   scales: {
      xAxes: [
         {
            display: false,
         }],
      yAxes: [
         {
            display: false,
         }],
   },
   elements: {
      line: {
         borderWidth: 2,
      },
      point: {
         radius: 0,
         hitRadius: 10,
         hoverRadius: 4,
      },
   },
};
const cardChartData4 = {
   labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
   datasets: [
      {
         label: 'My First dataset',
         backgroundColor: 'rgba(255,255,255,.3)',
         borderColor: 'transparent',
         data: [78, 81, 80, 45, 34, 12, 40, 75, 34, 89, 32, 68, 54, 72, 18, 98],
         barPercentage: 0.6,
      },
   ],
};
const cardChartOpts4 = {
   tooltips: {
      enabled: false,
      custom: CustomTooltips
   },
   maintainAspectRatio: false,
   legend: {
      display: false,
   },
   scales: {
      xAxes: [
         {
            display: false,
         }],
      yAxes: [
         {
            display: false,
         }],
   },
};

function random(min, max)
{
   return Math.floor(Math.random() * (max - min + 1) + min);
}

let elements = 27;
let data1 = [];
let data2 = [];
let data3 = [];

for (let i = 0; i <= elements; i++)
{
   data1.push(random(50, 200));
   data2.push(random(80, 100));
   data3.push(65);
}

class Dashboard extends Component
{
   constructor(props)
   {
      super(props);

      this.state = {
         gridData: [],
         dropdownOpen: false,
         radioSelected: 2,
         OrdersCount: 0,
         ProductsCount: 0,
         DistributorsCount: 0,
         CustomersCount: 0,
         Top10label: [],
         Top10value: []
      };
      this.getData();
   }

   getData()
   {

      BB.FetchJSON('api/Dashboard/GetDashBoardData').then(data =>
      {
         let res = data[0];
         if (res)
         {
            this.setState({

               OrdersCount: res.orders,
               ProductsCount: res.products,
               DistributorsCount: res.distributors,
               CustomersCount: res.customers
            })
         }
      })

      BB.FetchJSON('api/Dashboard/GetTop10').then(data =>
      {
         let ths = this;
         let labels = [];
         let values = [];

         for (let i = 0; i < 5; i++)
         {
            labels.push(data[i].label);
            values.push(data[i].value);

            if (i === 4)
            {
               ths.setState({
                  Top10label: labels,
                  Top10value: values
               })
            }
         }
      })

   }

   render()
   {
      let pie = {
         labels: this.state.Top10label,
         datasets: [
            {
               data: this.state.Top10value,
               backgroundColor: [

                  'MediumOrchid',
                  'MediumPurple',
                  'Orchid',
                  'Fuchsia',
                  'LightSeaGreen',
                  'DarkCyan',
                  'Teal',

               ],
               hoverBackgroundColor: [

                  'MediumOrchid',
                  'MediumPurple',
                  'Orchid',
                  'Fuchsia',
                  'LightSeaGreen',
                  'DarkCyan',
                  'Teal',
               ],
            }],
      };
      const options = {
         tooltips: {
            enabled: false,
            custom: CustomTooltips
         },
         legend: {
            display: false
         },
         maintainAspectRatio: true
      }
      const bar = {
         labels: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
         datasets: [
            {
               label: 'My First dataset',
               backgroundColor: 'rgba(255,99,132,0.2)',
               borderColor: 'rgba(255,99,132,1)',
               borderWidth: 1,
               hoverBackgroundColor: 'rgba(255,99,132,0.4)',
               hoverBorderColor: 'rgba(255,99,132,1)',
               data: [65, 59, 80, 81, 56, 55, 40],
            },
         ],
      };
      const line = {
         labels: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'],
         datasets: [
            {
               label: 'Satışlarım',
               fill: false,
               lineTension: 0.1,
               backgroundColor: 'rgba(75,192,192,0.4)',
               borderColor: 'rgba(75,192,192,1)',
               borderCapStyle: 'butt',
               borderDash: [],
               borderDashOffset: 0.0,
               borderJoinStyle: 'miter',
               pointBorderColor: 'rgba(75,192,192,1)',
               pointBackgroundColor: '#fff',
               pointBorderWidth: 1,
               pointHoverRadius: 5,
               pointHoverBackgroundColor: 'rgba(75,192,192,1)',
               pointHoverBorderColor: 'rgba(220,220,220,1)',
               pointHoverBorderWidth: 2,
               pointRadius: 1,
               pointHitRadius: 10,
               data: [65, 59, 80, 81, 56, 55, 40],
            },
         ],
      };

      let {OrdersCount, ProductsCount, DistributorsCount, CustomersCount} = this.state;
      return (
         <div className="animated fadeIn">
            <Row>
               <Col xs="12" sm="6" lg="3">
                  <Card className="text-white bg-info">
                     <CardBody className="pb-0">
                        <div className="text-value">{OrdersCount}</div>
                        <div>Toplam yapılan satış</div>
                     </CardBody>
                     <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                        <Line data={cardChartData2} options={cardChartOpts2} height={70}/>
                     </div>
                  </Card>
               </Col>

               <Col xs="12" sm="6" lg="3">
                  <Card className="text-white bg-primary">
                     <CardBody className="pb-0">
                        <div className="text-value">{ProductsCount}</div>
                        <div>Ürün çeşidi</div>
                     </CardBody>
                     <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                        <Line data={cardChartData1} options={cardChartOpts1} height={70}/>
                     </div>
                  </Card>
               </Col>

               <Col xs="12" sm="6" lg="3">
                  <Card className="text-white bg-warning">
                     <CardBody className="pb-0">
                        <div className="text-value">{DistributorsCount}</div>
                        <div>Toplam Distribütör</div>
                     </CardBody>
                     <div className="chart-wrapper" style={{height: '70px'}}>
                        <Line data={cardChartData3} options={cardChartOpts3} height={70}/>
                     </div>
                  </Card>
               </Col>

               <Col xs="12" sm="6" lg="3">
                  <Card className="text-white bg-danger">
                     <CardBody className="pb-0">
                        <div className="text-value">{CustomersCount}</div>
                        <div>Toplam Müşteri</div>
                     </CardBody>
                     <div className="chart-wrapper mx-3" style={{height: '70px'}}>
                        <Bar data={cardChartData4} options={cardChartOpts4} height={70}/>
                     </div>
                  </Card>
               </Col>
            </Row>
            <Row>
               <Col lg={4}>
                  <Card>
                     <CardHeader>
                        En Çok Satılan 5 Ürün
                        <div className="card-header-actions">
                           <a className="card-header-action">
                              <small className="text-muted">Ürünler'e Git</small>
                           </a>
                        </div>
                     </CardHeader>
                     <CardBody>
                        <div className="chart-wrapper">
                           <Doughnut data={pie}/>
                        </div>
                     </CardBody>
                  </Card>
               </Col>
               <Col lg={4}>
                  <Card>
                     <CardHeader>
                        Günlük Satış Geçmişi (Adet)
                        <div className="card-header-actions">
                           <a className="card-header-action">
                              <small className="text-muted">Satış Geçmişine Git</small>
                           </a>
                        </div>
                     </CardHeader>
                     <CardBody>
                        <div className="chart-wrapper">
                           <Bar data={bar} options={options}/>
                        </div>
                     </CardBody>
                  </Card>
               </Col>
               <Col lg={4}>
                  <Card>
                     <CardHeader>
                        Günlük Kar Oranları (TL)
                        <div className="card-header-actions">
                           <a className="card-header-action">
                              <small className="text-muted">Satış Geçmişine Git</small>
                           </a>
                        </div>
                     </CardHeader>
                     <CardBody>
                        <div className="chart-wrapper">
                           <Line data={line} options={options}/>
                        </div>
                     </CardBody>
                  </Card>

               </Col>
            </Row>
            <Row>
               <Col lg={12}>
                  <Card>
                     <CardHeader>
                        Aylara Göre Kar Oranları (TL)
                        <div className="card-header-actions">
                           <a className="card-header-action">
                              <small className="text-muted">Satış Geçmişine Git</small>
                           </a>
                        </div>
                     </CardHeader>
                     <CardBody>
                        <div className="chart-wrapper">
                           <Line
                              data={line}
                              options={{
                                 tooltips: {
                                    enabled: false,
                                    custom: CustomTooltips
                                 },
                                 legend: {
                                    display: false
                                 },
                                 maintainAspectRatio: false,
                              }}/>
                        </div>
                     </CardBody>
                  </Card>

               </Col>
            </Row>
         </div>
      );
   }
}

export default Dashboard;
