import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.scss';
import DefaultLayout from './containers/DefaultLayout';
import Login from './views/Login/Login';
import trMessages from "devextreme/localization/messages/tr.json";
import {loadMessages, locale} from "devextreme/localization";
import config from 'devextreme/core/config';

const loading = () => <div className="animated fadeIn pt-3 text-center">Lütfen Bekleyiniz...</div>;

class App extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {};
      loadMessages(trMessages);
      locale(navigator.language);
      config({defaultCurrency: 'TRY'});
   }

   render()
   {
      return (
         <HashRouter>
            <React.Suspense fallback={loading()}>
               <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                  <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>}/>
               </Switch>
            </React.Suspense>
         </HashRouter>
      );
   }
}

export default App;
