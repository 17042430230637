import React, {Component, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import * as router from 'react-router-dom';
import {Container} from 'reactstrap';

import {
   AppAside,
   AppFooter,
   AppHeader,
   AppSidebar,
   AppSidebarFooter,
   AppSidebarForm,
   AppSidebarHeader,
   AppSidebarMinimizer,
   AppBreadcrumb2 as AppBreadcrumb,
   AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
import navigation from '../../_nav';
import routes from '../../routes';
import metrics from "../../common/metrics";

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component
{

   loading = () => <div className="animated fadeIn pt-1 text-center">Lütfen Bekleyiniz...</div>

   signOut(e)
   {
      e.preventDefault()
      metrics.loginUser = undefined;
      this.props.history.push('/login')
   }

   render()
   {
      if (metrics.loginUser === undefined)
      {
         return <Redirect push to="/login"/>;
      }
      else
      {
         return (
            <div className="app">
               <img src={require('../../assets/img/backgroundImage.jpeg')}
                    style={{
                       position: 'absolute',
                       width: '150%',
                       height: '100%',
                       overflow: 'hidden',
                       opacity: '40%'
                    }}/>
               <AppHeader fixed>
                  <Suspense fallback={this.loading()}>
                     <DefaultHeader onLogout={e => this.signOut(e)}/>
                  </Suspense>
               </AppHeader>
               <div className="app-body">
                  <AppSidebar fixed display="lg">
                     <AppSidebarHeader/>
                     <AppSidebarForm/>
                     <Suspense>
                        <AppSidebarNav navConfig={navigation} {...this.props} router={router}/>
                     </Suspense>
                     <AppSidebarFooter/>
                     <AppSidebarMinimizer/>
                  </AppSidebar>
                  <main className="main">
                     <AppBreadcrumb appRoutes={routes} router={router}/>


                     <Container fluid>

                        <Suspense fallback={this.loading()}>

                           <Switch>


                              {routes.map((route, idx) =>
                              {
                                 return route.component ? (
                                    <Route
                                       key={idx}
                                       path={route.path}
                                       exact={route.exact}
                                       name={route.name}
                                       render={props => (
                                          <route.component {...props} />
                                       )}/>
                                 ) : (null);
                              })}
                              <Redirect from="/" to="/main"/>
                           </Switch>
                        </Suspense>
                     </Container>
                  </main>
                  <AppAside fixed>
                     <Suspense fallback={this.loading()}>
                        <DefaultAside/>
                     </Suspense>
                  </AppAside>
               </div>
               <AppFooter>
                  <Suspense fallback={this.loading()}>
                     <DefaultFooter/>
                  </Suspense>
               </AppFooter>
            </div>
         );
      }
   }
}

export default DefaultLayout;
