import React, {PureComponent} from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import * as BB from "../../components/customComponent";
import Button from "reactstrap/es/Button";
import notify from "devextreme/ui/notify";

class BrandAndModel extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridDataBrand: [],
         gridDataModel: [],
         selectedBrand: null,
         selectedModel: null,
         selectedBatteryType: ''
      };
      this.getData();
   }

   getData()
   {
      BB.FetchJSON('api/Product/GetBrands').then(res =>
      {
         this.setState({gridDataBrand: res, selectedBrand: '', selectedBatteryType: ''});
      });

      BB.FetchJSON('api/Product/GetModels').then(res =>
      {
         this.setState({gridDataModel: res, selectedModel: '', selectedBatteryType: ''});
      });
   }

   deleteData(key, e)
   {
      let url = key === 'marka' ? 'api/Product/DeleteBrand?ID=' + e.data.id : 'api/Product/DeleteModel?ID=' + e.data.id;
      let ths = this;

      BB.FetchJSON(url).then((res) =>
      {
         if (res)
         {
            notify('Başarılı!', 'success', 1000);
            ths.getData();
         }
         else
         {
            notify('Silme Başarısız, İlgili kayıt satışta kullanılmış!', 'error', 2000);
            ths.getData();
         }
      });
   }

   handleChange(key, value)
   {
      this.setState({[key]: value});
   }

   addDataBrands()
   {

      let {selectedBrand} = this.state;

      if (selectedBrand)
      {
         BB.FetchJSON('api/Product/AddOrUpdateBrands?name=' + selectedBrand).then(res =>
         {
            if (res === 1)
            {
               this.getData();
               notify('Marka Başarıyla Kaydeildi!', "success", 2000);
            }
            else
            {
               notify('Marka Kaydederken Hata Alındı!', "error", 1000);
            }
         })
      }
      else
      {
         notify('Marka Adı Boş Olamaz!', "warning", 2000);
      }
   }

   addDataModels()
   {
      let {selectedModel, selectedBatteryType} = this.state;

      if (selectedModel)
      {
         BB.FetchJSON('api/Product/AddOrUpdateModels?name=' + selectedModel + '&batteryType=' + selectedBatteryType).then(res =>
         {
            if (res === 1)
            {
               this.getData();
               notify('Model Başarıyla Kaydeildi!', "success", 2000);
            }
            else
            {
               notify('Model Kaydederken Hata Alındı!', "error", 1000);
            }
         })
      }
      else
      {
         notify('Model Adı Boş Olamaz!', "warning", 2000);
      }
   }

   editData(e)
   {
      let id = e.key.id;
      let name = e.key.name;
      let battery = e.key.batteryNumber;


      BB.FetchJSON('api/Product/AddOrUpdateModels?MID=' + id.toString() + '&name=' + name + '&batteryType=' + battery)
      .then(res =>
      {
         if (res === 1)
         {
            this.getData();
            notify('Model Başarıyla Kaydedildi!', "success", 1000);
         }
         else
         {
            notify('Model Kaydederken Hata Alındı!', "error", 1000);
         }
      })

   }

   render()
   {
      return (
         <Row>
            <Col xl={2}>
               <Card>
                  <CardHeader>
                     <strong>Marka Kaydet</strong>
                  </CardHeader>
                  <CardBody>
                     {BB.InputWithLabel('selectedBrand', 'Marka Giriniz', 'text', this, '')}
                     <Button
                        style={{float: 'right'}}
                        color={'primary'}
                        block
                        onClick={() => this.addDataBrands()}
                     >
                        Kaydet
                     </Button>
                  </CardBody>
               </Card>
            </Col>
            <Col xl={4}>
               <Card>
                  <CardHeader>
                     Markalar
                  </CardHeader>
                  <CardBody>
                     {BB.DxDataGridBrand(this.state.gridDataBrand, 'marka', this)}
                  </CardBody>
               </Card>
            </Col>

            <Col xl={2}>
               <Card>
                  <CardHeader>
                     <strong>Model Kaydet</strong>
                  </CardHeader>
                  <CardBody>
                     {BB.InputWithLabel('selectedModel', 'Model Giriniz', 'text', this, '')}
                     {BB.InputWithLabel('selectedBatteryType', 'Pil No Giriniz', 'text', this, '')}
                     <Button
                        style={{float: 'right'}}
                        color={'primary'}
                        block
                        onClick={() => this.addDataModels()}
                     >
                        Kaydet
                     </Button>
                  </CardBody>
               </Card>
            </Col>
            <Col xl={4}>
               <Card>
                  <CardHeader>
                     Modeller
                  </CardHeader>
                  <CardBody>
                     {BB.DxDataGridModel(this.state.gridDataModel, 'model', this)}
                  </CardBody>
               </Card>
            </Col>
         </Row>
      );
   }
}

export default BrandAndModel;
