import React, { PureComponent } from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import * as BB from '../../components/customComponent'
import Button from "reactstrap/es/Button";
import notify from 'devextreme/ui/notify';

export default class Products extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridData: [],
            selectedSN: '',
            selectedDistributorID: '',
            selectedBrandID: '',
            selectedModelID: '',
            selectedStock: '',
            selectedBarcode: '',
            selectedEntrPrice: 0,
        }

        this.getData();
    }

    getData() {
        BB.FetchJSON('api/Product/GetProduct').then(data => {
            this.setState({
                gridData: data,
                selectedSN: '',
                selectedDistributorID: '',
                selectedBrandID: '',
                selectedModelID: '',
                selectedStock: '',
                selectedBarcode: '',
                selectedEntrPrice: 0
            });
        })
    }

    addData() {
        let {
            selectedSN,
            selectedDistributorID,
            selectedBrandID,
            selectedModelID,
            selectedBarcode,
            selectedEntrPrice
        } = this.state;

        if (selectedSN &&
            selectedDistributorID &&
            selectedBrandID &&
            selectedModelID &&
            selectedBarcode &&
            selectedEntrPrice) {
            BB.FetchJSON(
                'api/Product/AddOrUpdateProduct' +
                '?BID=' + selectedBrandID +
                '&disID=' + selectedDistributorID +
                '&entryPrice=' + selectedEntrPrice +
                '&MID=' + selectedModelID +
                '&sn=' + selectedSN +
                '&stockCount=1' +
                '&barcode=' + selectedBarcode
            ).then(res => {
                if (res === 1) {
                    this.getData();
                    notify('Stok Başarıyla Kaydedildi!', "success", 2000);
                }
                else {
                    notify('Stok Kaydederken Hata Alındı!', "error", 1000);
                }
            })
        }
        else {
            notify('Tüm alanların dolu olduğuna emin olun!', "warning", 2000);
        }
    }

    deleteData(e) {
        let url = 'api/Product/DeleteProduct?ID=' + e.data.id;
        let ths = this;

        BB.FetchJSON(url).then((res) => {
            if (res) {
                notify('Başarılı!', 'success', 1000);
                ths.getData();
            }
            else {
                notify('Silme Başarısız, Ürün satışta kullanılmış!', 'error', 2000);
                ths.getData();
            }
        });
    }

    handleChange(key, value) {
        this.setState({ [key]: value });
    }

    render() {
        return (
            <Row>
                <Row>
                    <Col xl={2}>
                        <Card>
                            <CardHeader>
                                Stok Oluştur
                     </CardHeader>
                            <CardBody>
                                {BB.InputWithLabel('selectedSN', 'Seri Numarası', 'text', this)}
                                {BB.DxDropDownWithURL('selectedDistributorID', 'Distribütör Seçiniz', this, 'api/Distributor/GetDis')}
                                {BB.DxDropDownWithURL('selectedBrandID', 'Marka Seçiniz', this, 'api/Product/GetBrands')}
                                {BB.DxDropDownWithURL('selectedModelID', 'Model Seçiniz', this, 'api/Product/GetModels')}
                                {BB.InputWithLabel('selectedEntrPrice', 'Giriş Fiyatı', 'number', this)}
                                {BB.InputWithLabel('selectedBarcode', 'Barkodu', 'text', this)}
                                <Button
                                    style={{ float: 'right' }}
                                    color={'primary'}
                                    block
                                    onClick={() => this.addData()}
                                >
                                    Kaydet
                        </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={10}>
                        <Card>
                            <CardHeader>
                                Stoklar
                     </CardHeader>
                            <CardBody>
                                {BB.DxDataGridProducts(this.state.gridData, this)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Row>
        );
    }
}
