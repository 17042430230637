import React, {Component} from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import * as BB from '../../components/customComponent'
import Button from "reactstrap/es/Button";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import {post} from 'axios';
import notify from "devextreme/ui/notify";
import {Redirect} from "react-router-dom";
import {FormGroup, Label} from "reactstrap";

class CreateOrder extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         isError: true,
         selectedSN: '',
         selectedBarcodes: '',

         selectedStock: 0,
         visibleCustomerSelect: false,
         tempCustomer: {},
         redirect: false,

         selectedDate: null,
         selectedFirstPrice: 0,
         selectedSecondDate: null,
         selectedSecondPrice: 0,
         selectedThirdDate: null,
         selectedThirdPrice: 0,
         selectedFourthDate: null,
         selectedFourthPrice: 0,
         selectedFifthDate: null,
         selectedFifthPrice: 0,
         totalKar: '',
         selectedEntyPrice: 0,
         selectedYon: '',
         addCustomer: false,

         selectedNewCustomerName: '',
         selectedNewCustomerSurname: '',
         selectedNewCustomerPhone: '',
         selectedNewCustomerAdress: '',
         selectedNewCustomerSocial: '',
         selectedMedullaSifresi: '',
         selectedTCNo: ''
      }
   }

   getCustomers()
   {
      BB.FetchJSON('api/Customer/GetCustomer').then(data =>
      {
         this.setState({
            gridData: data,
            visibleCustomerSelect: true,
            addCustomer: false,
            selectedNewCustomerName: '',
            selectedNewCustomerSurname: '',
            selectedNewCustomerPhone: '',
            selectedNewCustomerAdress: '',
            selectedNewCustomerSocial: '',
            selectedMedullaSifresi: '',
            selectedTCNo: '',
         });
      })
   }

   getProduct()
   {
      let {selectedSN, selectedBarcodes} = this.state;
      let isErr = true;
      var URL = selectedBarcodes !== '' ? 'api/Product/GetProduct?ID=' + selectedBarcodes + '&isSN=false' : selectedSN !== '' ? 'api/Product/GetProduct?ID=' + selectedSN + '&isSN=true' : undefined;

      if (URL === undefined)
      {
         notify('Herhangi bir arama kriteri giriniz', 'warning', '1000');
         return;
      }

      BB.FetchJSON(URL).then(res =>
      {
         if (res.length > 0)
         {
            var data = res[0];

            if (data.stock == 0)
            {
               notify('İlgili ürünün stoğu 0 görünmektedir! Satış Yapamazsınız!', 'error', 2000);
               isErr = true;
            }
            else
            {
               isErr = false;
            }

            this.setState({
               selectedPID: data.id,
               selectedSN: data.serialNumber,
               selectedBarcodes: data.barcode,
               selectedBrand: data.brandName,
               selectedModel: data.modelName,
               selectedEntyDate: new Date(data.entryDate).toLocaleDateString('tr'),
               selectedEntyPrice: data.entryPrice,
               selectedDistributorID: data.distributorID,
               selectedDistributor: data.distributorName,
               selectedDistributorPhone: data.distributorPhone,
               selectedDistributorAdress: data.distributorAdress,
               selectedStock: data.stock,
               selectedBatteryType: data.batteryNumber,
               isError: isErr
            });
         }
         else
         {
            this.setState({
               selectedSN: '',
               selectedBarcodes: ''
            })
            notify('Sistemde ürün bulunamadı!', 'error', 2000);
         }
      })
   }

   handleChange(key, value)
   {
      this.setState({[key]: value}, function ()
      {
         if (key === 'selectedFirstPrice' || key === 'selectedSecondPrice' || key === 'selectedThirdPrice' || key === 'selectedFourthPrice' || key === 'selectedFifthPrice')
         {
            let {
               selectedFirstPrice,
               selectedSecondPrice,
               selectedThirdPrice,
               selectedFourthPrice,
               selectedFifthPrice,
               selectedEntyPrice
            } = this.state;

            let total = Number.parseFloat(selectedFirstPrice) + Number.parseFloat(selectedSecondPrice) + Number.parseFloat(selectedThirdPrice) + Number.parseFloat(selectedFourthPrice) + Number.parseFloat(selectedFifthPrice);

            let result = total - selectedEntyPrice;

            if (result)
            {
               this.setState({totalKar: 'Bu üründe Toplamda ' + result + ' ₺ Kar elde ediyorsunuz.'});
            }
            else
            {
               this.setState({totalKar: ''});
            }
         }
      });
   }

   addOrder()
   {

      const formData = new FormData();

      let PID = this.state.selectedPID;
      let saleAmount = 1;

      let CID = this.state.selectedCustomerID;
      let Desc = this.state.selectedCustomerDetail ? this.state.selectedCustomerDetail : '';
      let saleDate = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
      let entryPrice = this.state.selectedEntyPrice;

      let pesinTutar = this.state.selectedFirstPrice;
      let birinciTutar = this.state.selectedSecondPrice;
      let ikinciTutar = this.state.selectedThirdPrice;

      let pesinTarih = this.state.selectedDate;
      let birinciTarih = this.state.selectedSecondDate !== null ? new Date(this.state.selectedSecondDate).toLocaleDateString() + ' ' + new Date(this.state.selectedSecondDate).toLocaleTimeString() : null;
      let ikinciTarih = this.state.selectedThirdDate !== null ? new Date(this.state.selectedThirdDate).toLocaleDateString() + ' ' + new Date(this.state.selectedThirdDate).toLocaleTimeString() : null;
      let salePrice = Number.parseInt(pesinTutar) + Number.parseInt(birinciTutar) + Number.parseInt(ikinciTutar);

      let Yon = this.state.selectedYon;

      if (Yon && (pesinTutar && pesinTarih || birinciTutar && birinciTarih || ikinciTutar && ikinciTarih))
      {
         formData.append('PID', PID);
         formData.append('saleAmount', saleAmount);
         formData.append('salePrice', salePrice);
         formData.append('CID', CID);
         formData.append('Desc', Desc);
         formData.append('saleDate', saleDate);
         formData.append('entryPrice', entryPrice);
         formData.append('pesinTutar', pesinTutar);
         formData.append('pesinTarih', pesinTarih);
         formData.append('birinciTutar', birinciTutar);
         formData.append('birinciTarih', birinciTarih);
         formData.append('ikinciTutar', ikinciTutar);
         formData.append('ikinciTarih', ikinciTarih);
         formData.append('yon', Yon.toString());

         post('api/Order/AddOrUpdateOrder', formData, {headers: {'content-type': 'multipart/form-data'}})
         .then(res =>
         {
            if (res.status === 200)
            {
               this.setState({redirect: true})
               notify('Satış başarıyla kaydedilmiştir.', 'success', 1000);
            }
         });
      }
      else
      {
         notify('Lütfen Zorunlu alanları doldurunuz!', 'warning', 2000);
      }
   }

   addNewCustomer()
   {
      let {
         selectedNewCustomerName,
         selectedNewCustomerSurname,
         selectedNewCustomerPhone,
         selectedNewCustomerAdress,
         selectedNewCustomerSocial,
         selectedMedullaSifresi,
         selectedTCNo
      } = this.state;

      if (selectedNewCustomerName && selectedNewCustomerSurname && selectedNewCustomerPhone && selectedNewCustomerAdress && selectedNewCustomerSocial)
      {
         BB.FetchJSON('api/Customer/AddOrUpdateOrderCustomer?name=' + selectedNewCustomerName + '&phone=' + selectedNewCustomerPhone + '&adres=' + selectedNewCustomerAdress + '&surname=' + selectedNewCustomerSurname + '&ss=' + selectedNewCustomerSocial + '&medullaNo=' + selectedMedullaSifresi + '&tcNo=' + selectedTCNo).then(res =>
         {
            if (res === 1)
            {
               this.getCustomers();
               notify('Müşteri Başarıyla Kaydeildi!', "success", 1000);
            }
            else
            {
               notify('Müşteri Kaydederken Hata Alındı!', "error", 1000);
            }
         })
      }
      else
      {

      }
   }

   render()
   {
      if (this.state.redirect === true)
      {
         return <Redirect push to="/main"/>;
      }
      else
      {
         return (
            <div>
               <Row>
                  <Col xl={1}></Col>
                  <Col xl={10}>
                     <Card>
                        <CardHeader>
                           Satış Yap
                        </CardHeader>
                        <CardBody>
                           <Row style={{borderBottom: '1px solid orange'}}>
                              <Col xl={3}>
                                 {BB.InputWithLabel('selectedSN', 'Seri No', 'text', this)}
                              </Col>
                              <Col xl={3}>
                                 {BB.InputWithLabel('selectedBarcodes', 'Barkod', 'text', this)}
                              </Col>
                              <Col xl={3}>
                                 <Button
                                    style={{marginTop: 28}}
                                    onClick={() => this.getProduct()}
                                    outline
                                    color={'success'}
                                 >
                                    Ürünü Bul
                                 </Button>
                              </Col>
                              <Col xl={3}>
                                 <strong style={{fontSize: 20, float: 'right', marginRight: 20}}>Stok Adet
                                    : <strong
                                       style={{fontSize: 30}}>{this.state.selectedStock}</strong></strong>
                              </Col>
                           </Row>

                           <Row style={{marginTop: 13, borderBottom: '1px solid green'}}>
                              <Col xl={3}>
                                 {BB.InputWithLabelReadOnly('selectedBrand', 'Marka', 'text', this)}
                              </Col>
                              <Col xl={2}>
                                 {BB.InputWithLabelReadOnly('selectedModel', 'Model', 'text', this)}
                              </Col>
                              <Col xl={1}>
                                 {BB.InputWithLabelReadOnly('selectedBatteryType', 'Pil', 'text', this)}
                              </Col>
                              <Col xl={3}>
                                 {BB.InputWithLabelReadOnly('selectedEntyDate', 'Giriş Tarihi', 'text', this)}
                              </Col>
                              <Col xl={3}>
                                 {BB.InputWithLabelReadOnly('selectedEntyPrice', 'Giriş Fiyatı', 'text', this)}
                              </Col>
                           </Row>

                           <Row style={{marginTop: 13, borderBottom: '1px solid blue'}}>
                              <Col xl={3}>
                                 {BB.InputWithLabelReadOnly('selectedDistributor', 'Distribütör Firma', 'text', this)}
                              </Col>
                              <Col xl={3}>
                                 {BB.InputWithLabelReadOnly('selectedDistributorPhone', 'Telefonu', 'text', this)}
                              </Col>
                              <Col xl={6}>
                                 {BB.InputWithLabelReadOnly('selectedDistributorAdress', 'Adresi', 'text', this)}
                              </Col>
                           </Row>

                           <Row style={{marginTop: 13, borderBottom: '1px solid black'}}>
                              <Col xl={1}>
                                 <Button
                                    disabled={this.state.isError}
                                    style={{height: 144}}
                                    block
                                    color={'primary'}
                                    outline
                                    onClick={() => this.getCustomers()}
                                 >
                                    BUL
                                 </Button>
                              </Col>

                              <Col xl={2}>
                                 {BB.InputWithLabelReadOnly('selectedCustomerName', 'Müşteri Adı', 'text', this)}

                                 {BB.InputWithLabelReadOnly('selectedCustomerPhone', 'Telefonu', 'text', this)}
                              </Col>

                              <Col xl={2}>
                                 {BB.InputWithLabelReadOnly('selectedCustomerSurName', 'Müşteri Soyadı', 'text', this)}

                                 {BB.InputWithLabelReadOnly('selectedCustomerSocial', 'Sosyal Güvence', 'text', this)}
                              </Col>

                              <Col xl={2}>
                                 {BB.InputWithLabelReadOnly('selectedCustomerSurName', 'Medulla Şifresi', 'text', this)}

                                 {BB.InputWithLabelReadOnly('selectedCustomerSocial', 'TC Kimlik No', 'text', this)}
                              </Col>

                              <Col xl={2}>
                                 {BB.TextAreaWithLabel('selectedCustomerAdress', 'Adresi', '', 5, this)}
                              </Col>

                              <Col xl={2}>
                                 {BB.TextAreaWithLabel('selectedCustomerDetail', 'Satış Notu', '', 5, this)}
                              </Col>

                              <Col xl={1}>
                                 <FormGroup>
                                    <Label>
                                       <strong>Yön Belirtiniz</strong>
                                    </Label>
                                    <div
                                       style={{marginTop: 10}}
                                    >
                                       <input style={{height: 20, width: 20}} type="radio" value="SOL"
                                              onChange={() => this.setState({selectedYon: 'SOL'})}
                                              name="gender"/> <a>SOL</a>
                                       <br></br>
                                       <input style={{height: 20, width: 20, marginTop: 10}} type="radio" value="SAĞ"
                                              onChange={() => this.setState({selectedYon: 'SAĞ'})}
                                              name="gender"/> <a>SAĞ</a>
                                    </div>
                                 </FormGroup>
                              </Col>
                           </Row>

                           <Row style={{marginTop: 13, borderBottom: '1px solid black', backgroundColor: 'honeydew'}}>

                              <Col xl={2}>
                                 {BB.DateSelectWithLabel('selectedDate', 'Sipariş Oluşurma Tarihi', this)}
                              </Col>

                              <Col xl={2}>
                                 {BB.InputWithLabel('selectedFirstPrice', 'Peşinat Tutar', 'number', this)}
                              </Col>

                              <Col xl={5}>

                              </Col>

                              <Col xl={3}>
                                 <Button
                                    disabled={this.state.isError}
                                    // style={{height: 144}}
                                    block
                                    style={{marginTop: 28}}
                                    // outline
                                    color={'success'}
                                    onClick={() => this.addOrder()}

                                 >
                                    İşlemi Tamamla
                                 </Button>
                              </Col>

                           </Row>

                        </CardBody>
                        <CardFooter>
                           {this.state.totalKar}
                        </CardFooter>
                     </Card>
                  </Col>
                  <Col xl={1}></Col>
               </Row>

               <Modal
                  size={'xl'}
                  style={{zIndex: 1}}
                  centered={true}
                  isOpen={this.state.visibleCustomerSelect}
               >
                  <ModalHeader>{'Müşteri Bul'}</ModalHeader>
                  <ModalBody>

                     {BB.DxDataGridCreateOrder(this.state.gridData, this)}

                     <Row style={{float: 'right', marginTop: 15}}>
                        <Button
                           color={'info'}
                           outline
                           style={{width: 100}}
                           onClick={() => this.setState({tempCustomer: {}, visibleCustomerSelect: false})}>
                           Vazgeç
                        </Button>

                        <Button
                           color={'primary'}
                           outline
                           style={{marginLeft: 20, marginRight: 15, width: 100}}
                           onClick={() => this.setState({addCustomer: true})}>
                           Yeni
                        </Button>

                        <Button
                           color={'success'}
                           outline
                           style={{marginLeft: 0, marginRight: 15, width: 100}}
                           onClick={() => this.setState({
                              visibleCustomerSelect: false,
                              selectedCustomerID: this.state.tempCustomer.id,
                              selectedCustomerName: this.state.tempCustomer.name,
                              selectedMedullaSifresi: this.state.tempCustomer.medullaSifresi,
                              selectedTCNo: this.state.tempCustomer.tcNo,
                              selectedCustomerPhone: this.state.tempCustomer.phone,
                              selectedCustomerSurName: this.state.tempCustomer.surname,
                              selectedCustomerAdress: this.state.tempCustomer.adress,
                              selectedCustomerSocial: this.state.tempCustomer.socialService,
                           })}>
                           Seç
                        </Button>
                     </Row>
                  </ModalBody>
               </Modal>

               <Modal
                  style={{zIndex: 2}}
                  centered={true}
                  isOpen={this.state.addCustomer}
               >
                  <ModalHeader>{'Müşteri Kaydet'}</ModalHeader>
                  <ModalBody>

                     {BB.InputWithLabel('selectedTCNo', 'TC No', 'text', this, '')}
                     {BB.InputWithLabel('selectedNewCustomerName', 'Müşteri İsmi Giriniz', 'text', this, '')}
                     {BB.InputWithLabel('selectedNewCustomerSurname', 'Müşteri Soyismi Giriniz', 'text', this, '')}
                     {BB.InputWithLabel('selectedNewCustomerPhone', 'Müşteri Telefon Giriniz', 'text', this, '')}
                     {BB.InputWithLabel('selectedMedullaSifresi', 'Medulla Şifresi Giriniz', 'text', this, '')}
                     {BB.InputWithLabel('selectedNewCustomerSocial', 'Müşteri Sosyal Güvencesi Seçiniz', 'text', this, '')}
                     {BB.TextAreaWithLabel('selectedNewCustomerAdress', 'Müşteri Adresi Giriniz', '', '5', this)}

                     <Row style={{float: 'right', marginTop: 15}}>
                        <Button
                           color={'info'}
                           outline
                           style={{width: 100}}
                           onClick={() => this.setState({addCustomer: false})}>
                           Vazgeç
                        </Button>

                        <Button
                           color={'success'}
                           outline
                           style={{marginLeft: 20, marginRight: 15, width: 100}}
                           onClick={() => this.addNewCustomer()}>
                           Kaydet
                        </Button>
                     </Row>
                  </ModalBody>
               </Modal>
            </div>
         );
      }
   }
}

export default CreateOrder;
