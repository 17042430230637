import React, {Component} from 'react';
import {
   Button,
   Card,
   CardBody,
   Col,
   Container,
   Form,
   Input,
   InputGroup,
   InputGroupAddon,
   InputGroupText,
   Row
} from 'reactstrap';
import * as BB from '../../components/customComponent'
import notify from "devextreme/ui/notify";
import metrics from "../../common/metrics";
import {Redirect} from "react-router-dom";

class Login extends Component
{
   constructor(props)
   {
      super(props);
      this.state = {
         userName: undefined,
         userPass: undefined,
         redirect: false
      }
   }

   checkLoginUser()
   {
      BB.FetchJSON('api/User/CheckLoginuser?UserName=' + this.state.userName + '&UserPassword=' + this.state.userPass).then(res =>
      {
         if (res.name !== '')
         {
            metrics.loginUser = res.name;

            this.setState({redirect: true});
         }
         else
         {
            notify('Hatalı Kullanıcı bilgileri!!', 'danger', 3000);
         }
      });
   }

   render()
   {

      if (this.state.redirect === true)
      {
         return <Redirect push to="/main"/>;
      }
      else
      {

         return (
            <div className="app flex-row align-items-center" style={{backgroundColor: 'black'}}>
               <img src={require('../../assets/img/login.jpg')}
                    style={{
                       position: 'absolute',
                       width: '100%',
                       height: '100%',
                       overflow: 'hidden',
                       opacity: '40%'
                    }}/>
               <Container>
                  <Row className="justify-content-center">
                     <Col md="5">
                        <Card className="p-4">
                           <CardBody>
                              <Form>
                                 <h1>Oturum Aç</h1>
                                 <p className="text-muted">Kullanıcı adı ve şifreniz ile oturum açabilirsiniz</p>
                                 <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                       <InputGroupText>
                                          <i className="icon-user"></i>
                                       </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" onChange={(e) => this.setState({userName: e.target.value})}
                                           placeholder="Kullanıcı adı" autoComplete="username"/>
                                 </InputGroup>
                                 <InputGroup className="mb-4">
                                    <InputGroupAddon addonType="prepend">
                                       <InputGroupText>
                                          <i className="icon-lock"></i>
                                       </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password" onChange={(e) => this.setState({userPass: e.target.value})}
                                           placeholder="Şifre" autoComplete="current-password"/>
                                 </InputGroup>
                                 <Row>
                                    <Col xs="6">
                                       <Button color="primary" className="px-4" onClick={() => this.checkLoginUser()}>Oturum
                                          Aç</Button>
                                    </Col>
                                 </Row>
                              </Form>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>
            </div>
         );
      }
   }
}

export default Login;
