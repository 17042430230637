import React, {PureComponent} from 'react';
import Row from "reactstrap/es/Row";
import * as BB from '../../components/customComponent'
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import Card from "reactstrap/es/Card";
import notify from "devextreme/ui/notify";

class OrderHistory extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         gridData: {}
      }
      this.getOrderHistory();
   }

   getOrderHistory()
   {
      BB.FetchJSON('api/Order/GetOrders').then(res =>
      {
         this.setState({gridData: res});
      })
   }

   deleteData = (e) =>
   {
      let url = 'api/Order/DeleteOrders?ID=' + e.data.id;

      BB.FetchJSON(url).then((res) =>
      {
         notify('Başarılı!', 'success', 1000);
      });
   }

   render()
   {
      return (
         <div>
            <Row>
               <Card>
                  <CardHeader>
                     Satışlar
                  </CardHeader>
                  <CardBody>
                     {BB.DxDataGridOrderHistory(this.state.gridData, this)}
                  </CardBody>
               </Card>
            </Row>
         </div>
      );
   }
}

export default OrderHistory;
