import React, {PureComponent} from 'react';

class Settings extends PureComponent
{
   static defaultProps = {};

   static propTypes = {};

   render()
   {
      return (
         <div></div>
      );
   }
}

export default Settings;
