import React, {PureComponent} from 'react';
import Row from "reactstrap/es/Row";
import Card from "reactstrap/es/Card";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import Col from "reactstrap/es/Col";
import * as BB from '../../components/customComponent';
import {Button, Modal} from "reactstrap";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import notify from "devextreme/ui/notify";

class GelirGider extends PureComponent
{
   constructor(props)
   {
      super(props);
      this.state = {
         selectedDistributor: undefined,
         selectedDateStart: new Date(),
         selectedDateEnd: new Date(new Date().setDate(new Date().getDate() + 1)),
         selectedDescription: undefined,
         selectedYon: 0,
         selectedTutar: undefined,
         visiblePopup: false,
         gelirData: [],
         giderData: [],
      }
   }

   getData()
   {
      let gelirData = [];
      let giderData = [];
      let {selectedDistributor, selectedDateStart, selectedDateEnd} = this.state;
      let str = selectedDateStart.getFullYear() + '-' + (selectedDateStart.getMonth() + 1) + '-' + selectedDateStart.getDate();
      let end = selectedDateEnd.getFullYear() + '-' + (selectedDateEnd.getMonth() + 1) + '-' + selectedDateEnd.getDate();

      BB.FetchJSON('api/GelirGider/GetDate?DistributorID=' + selectedDistributor + '&StrDate=' + str + '&EndDate=' + end).then(res =>
      {
         if (res && res.length > 0)
         {
            res.map((item, i) =>
            {
               item.yon === 0 ? gelirData.push(item) : giderData.push(item);
            })

            this.setState({gelirData: gelirData, giderData: giderData});
         }
         else
         {
            this.setState({gelirData: [], giderData: []});
         }
      })
   }

   addData()
   {
      let {selectedDescription, selectedDistributor, selectedYon, selectedTutar} = this.state;

      BB.FetchJSON('api/GelirGider/AddData?DistributorID=' + selectedDistributor + '&Description=' + selectedDescription + '&Yon=' + selectedYon + '&Tutar=' + selectedTutar).then(res =>
      {
         if (res && res === true)
         {
            this.setState({
               visiblePopup: false,
               selectedDescription: undefined,
               selectedYon: 0,
               selectedTutar: undefined
            })
            notify('Başarılı', 'success', 2000);

            this.getData();
         }
         else
         {
            notify('Hata!', 'danger', 2000);
         }
      })
   }

   deleteData(key, e)
   {
      BB.FetchJSON('api/GelirGider/DeleteData?GelirGiderID=' + e.data.id).then(res =>
      {
         if (res && res === true)
         {
            this.getData();

            notify('Başarılı', 'success', 2000);
         }
         else
         {
            notify('Hata!', 'danger', 2000);
         }
      })
   }

   handleChange(id, value)
   {
      this.setState({[id]: value});
   }

   render()
   {
      return (
         <div>
            <Row>
               <Col>
                  <Card>
                     <CardHeader>
                        Gelir Gider Takibi
                     </CardHeader>
                     <CardBody>
                        <Row>
                           <Col>
                              {BB.DxDropDownWithURL('selectedDistributor', 'Cari Seçiniz', this, 'api/Distributor/GetDis', '')}
                           </Col>
                           <Col>
                              {BB.DxDateSelectWithLabel('selectedDateStart', 'Başlangıç Tarihi', this, '')}
                           </Col>
                           <Col>
                              {BB.DxDateSelectWithLabel('selectedDateEnd', 'Bitiş Tarihi', this, '')}
                           </Col>
                           <Col xl={1} style={{alignSelf: 'center'}}>
                              <Button style={{marginTop: 10}} color={'success'} onClick={() => this.getData()}>
                                 Raporla
                              </Button>
                           </Col>
                        </Row>

                        <Row>
                           <Col xl={6}>
                              {BB.DxDataGridGelir(this.state.gelirData, 'aaa', this)}
                           </Col>
                           <Col xl={6}>
                              {BB.DxDataGridGider(this.state.giderData, 'aaa', this)}
                           </Col>
                        </Row>
                     </CardBody>
                     <CardFooter>
                        <Button style={{float: 'right'}} color={'success'}
                                onClick={() => this.state.selectedDistributor ? this.setState({visiblePopup: true}) : notify('Öncelikle cari seçiniz', 'warning', 2000)}>
                           Yeni Hareket Ekle
                        </Button>
                     </CardFooter>
                  </Card>
               </Col>
            </Row>

            <Modal
               style={{zIndex: 1}}
               centered={true}
               size={'sm'}
               isOpen={this.state.visiblePopup}
            >
               <ModalHeader>{'Yeni Hareket Ekle'}</ModalHeader>
               <ModalBody>

                  {BB.InputWithLabel('selectedDescription', 'Açıklama', 'text', this)}

                  {BB.InputWithLabel('selectedTutar', 'Tutar', 'number', this)}

                  {BB.DegreeOfUrencyButtons('selectedYon', 'Yön Seçiniz', this, 'Gelir', 'Gider', this.state.selectedYon)}


                  <Row style={{float: 'right', marginTop: 15, marginRight: 15}}>
                     <Button
                        color={'info'}
                        outline
                        style={{width: 100}}
                        onClick={() => this.setState({
                           visiblePopup: false,
                           selectedDescription: undefined,
                           selectedTutar: undefined,
                           selectedYon: 0
                        })}>
                        Vazgeç
                     </Button>
                     <Button
                        color={'success'}
                        outline
                        style={{width: 100, marginLeft: 20}}
                        onClick={() => this.addData()}>
                        Ekle
                     </Button>
                  </Row>
               </ModalBody>
            </Modal>
         </div>
      );
   }
}

export default GelirGider;
