export default {
   items: [
      {
         name: 'Anasayfa',
         url: '/main',
         icon: 'icon-drop',
      },
      {
         title: true,
         name: 'Satışlar',
         wrapper: {
            element: '',
            attributes: {}
         },
      },
      {
         name: 'Satış yap',
         url: '/order/create',
         icon: 'icon-drop',
      },
      {
         name: 'Satış Geçmişi',
         url: '/order/history',
         icon: 'icon-pencil',
      },
      {
         title: true,
         name: 'Diğer işlemler',
         wrapper: {
            element: '',
            attributes: {},
         },
      },

      {
         name: 'Stok Listesi',
         url: '/products',
         icon: 'icon-star',
      },
      {
         name: 'Marka Model',
         url: '/brandAndModel',
         icon: 'icon-star',
      },
      {
         name: 'Cari Listesi',
         url: '/distributor',
         icon: 'icon-pie-chart',
      },
      {
         name: 'Gelir Gider',
         url: '/gelirgider',
         icon: 'icon-star',
      },
      {
         name: 'Grafiksel Veriler',
         url: '/dashboard',
         icon: 'icon-star',
      },
   ],
};
